import { useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline, IoArrowForwardOutline, IoCloseCircleOutline, IoMenuOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../dados/api-client';
import { useQuery } from 'react-query';
import { IMenu } from '../dados/interfaces/home';

const fetchDataServicos = async () => {
    const response = await apiClient('/servicos', {
        method: 'GET',
    });
    return response;
};

const fetchDataVendas = async () => {
    const response = await apiClient('/vendas', {
        method: 'GET',
    });
    return response;
};

export default function CHeader() {

    const [servicos, setServicos] = useState(false);
    const [vendas, setVendas] = useState(false);
    const [openCategory, setOpenCategory] = useState<string | null>(null);
    const [openVendasCategory, setOpenVendasCategory] = useState<string | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const { data: servicosData, isLoading: servicosLoading } = useQuery<Array<IMenu>>('servicosData', fetchDataServicos);
    const { data: vendasData, isLoading: vendasLoading } = useQuery<Array<IMenu>>('vendasData', fetchDataVendas);

    const navigate = useNavigate();

    const navegar = (rota: string) => {
        navigate(rota);
    };

    const navegarMenu = (rota: string) => {
        navigate(rota);
        closeMenu();
    };

    const navegarExterno = (rota: string) => {
        window.open(rota, '_blank');
    };

    const toggleCategory = (nome: string) => {
        setOpenCategory(openCategory === nome ? null : nome);
    };

    const toggleVendasCategory = (nome: string) => {
        setOpenVendasCategory(openVendasCategory === nome ? null : nome);
    };

    const closeMenu = () => {
        setServicos(false);
        setVendas(false);
    };

    const toggleMobileMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="flex flex-col">
            <button className="flex flex-col justify-center items-center bg-blue-700 cursor-pointer transition-all hover:bg-blue-500 px-5 xl:px-0" onClick={() => navegarExterno('https://chbstore.com.br')}>
                <div className="flex flex-col  w-full lg:flex-row flex-1 max-w-7xl justify-between py-3 gap-5">
                    <div className="flex flex-row items-center gap-2 rounded-full transition-all">
                        <p className="text-white text-center text-sm">Loja CHB com seu merchandise favorito.</p>
                    </div>
                    <div className="flex flex-row items-center gap-2 rounded-full transition-all">
                        <p className="text-white text-center text-sm font-medium">Acessar CHB Store</p>
                        <IoArrowForwardOutline className='text-white' />
                    </div>
                </div>
            </button>
            <section className="flex flex-row justify-center items-center bg-black border-b border-white border-opacity-15 px-5 xl:px-0">
                <div className="flex flex-row flex-1 max-w-7xl justify-between items-center py-5 gap-5">
                    <img src='/logo.svg' alt="logo" />
                    <nav className='hidden lg:flex flex-row gap-5'>
                        <button className="transition-all text-white font-medium hover:text-blue-700" onClick={() => navegar('/')}>
                            <p>Inicio</p>
                        </button>
                        <button className="transition-all text-white font-medium hover:text-blue-700" onClick={() => navegar('/sobre')}>
                            <p>Sobre</p>
                        </button>
                        <button className="transition-all text-white font-medium hover:text-blue-700" onClick={() => setServicos(!servicos)}>
                            <p>Serviços</p>
                        </button>
                        <button className="transition-all text-white font-medium hover:text-blue-700" onClick={() => setVendas(!vendas)}>
                            <p>Venda</p>
                        </button>
                        <button className="transition-all text-white font-medium hover:text-blue-700" onClick={() => navegar('/contato')}>
                            <p>Contato</p>
                        </button>
                        <button className="transition-all text-white font-medium hover:text-blue-700" onClick={() => navegar('/trabalhe-conosco')}>
                            <p>Trabalhe conosco</p>
                        </button>
                    </nav>
                    <button className="hidden lg:flex flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-blue-500 hover:bg-blue-700" onClick={() => navegarExterno('https://app.chbrental.com.br')}>
                        <p className="text-white text-center text-sm font-medium">Acesso colaborador</p>
                    </button>
                    <button className="flex lg:hidden flex-row items-center gap-2 px-5 py-3 rounded-full transition-all bg-white text-black hover:text-white hover:bg-blue-700" onClick={() => toggleMobileMenu()}>
                        <IoMenuOutline className='text-[20px]'></IoMenuOutline>
                    </button>
                </div>
            </section>

            {/* Menu Mobile */}
            {isMenuOpen && (
                <div className="fixed inset-0 z-[60] bg-black bg-opacity-75" onClick={() => toggleMobileMenu()}>
                    <div className="flex flex-col fixed top-0 left-0 bg-white w-[250px] h-full" onClick={(e) => e.stopPropagation()}>
                        <div className="flex justify-between items-center border-b-2 border-gray-200 p-5">
                            <p className="text-black font-semibold">Menu</p>
                            <IoCloseCircleOutline className="cursor-pointer text-[20px]" onClick={() => toggleMobileMenu()} />
                        </div>
                        <nav className="flex flex-col gap-5 p-5">
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { navegar('/'); toggleMobileMenu(); }}>
                                Inicio
                            </button>
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { navegar('/sobre'); toggleMobileMenu(); }}>
                                Sobre
                            </button>
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { setServicos(!servicos); toggleMobileMenu(); }}>
                                Serviços
                            </button>
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { setVendas(!vendas); toggleMobileMenu(); }}>
                                Venda
                            </button>
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { navegar('/contato'); toggleMobileMenu(); }}>
                                Contato
                            </button>
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { navegar('/trabalhe-conosco'); toggleMobileMenu(); }}>
                                Trabalhe conosco
                            </button>
                            <button className="text-left font-semibold p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => { navegarExterno('https://app.chbrental.com.br'); toggleMobileMenu(); }}>
                                Acesso colaborador
                            </button>
                        </nav>
                    </div>
                </div>
            )}

            {/* Overlay for Services */}
            {servicos && (
                <div className="fixed inset-0 z-[60] bg-black bg-opacity-50" onClick={closeMenu}>
                    <div className="flex flex-col fixed left-0 top-0 bg-white min-w-[300px] h-full overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                        <div className="flex flex-row w-full justify-between items-center p-5 border-b-2">
                            <p className="text-black font-semibold">Serviços</p>
                            <IoCloseCircleOutline onClick={closeMenu} className="cursor-pointer text-[20px]" />
                        </div>
                        <div className="flex flex-col flex-1 p-5 gap-5">
                            {servicosLoading ? (
                                <div className='w-full h-[400px] bg-black animate-pulse' />
                            ) : (
                                servicosData?.map((servico, index) => (
                                    <div key={index} className="flex flex-col gap-5 transition-all">
                                        <div className="flex flex-row justify-between items-center p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => toggleCategory(servico.nome)}>
                                            <div className="flex flex-col">
                                                <p className="font-semibold">{servico.nome}</p>
                                            </div>
                                            <div className="flex flex-row justify-center items-center bg-gray-300 h-full p-3 rounded-lg">
                                                {openCategory === servico.nome ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                                            </div>
                                        </div>
                                        {openCategory === servico.nome &&
                                            servico.categorias.map((item, index) => (
                                                <div key={index} className="flex flex-col p-3 text-black border rounded-xl cursor-pointer hover:bg-gray-200" onClick={() => navegarMenu(`/categorias/${item.id}`)}>
                                                    <p>{item.nome}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Overlay for Sales */}
            {vendas && (
                <div className="fixed inset-0 z-[60] bg-black bg-opacity-50" onClick={closeMenu}>
                    <div className="flex flex-col fixed left-0 top-0 bg-white min-w-[300px] h-full overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                        <div className="flex flex-row w-full justify-between items-center p-5 border-b-2">
                            <p className="text-black font-semibold">Venda</p>
                            <IoCloseCircleOutline onClick={closeMenu} className="cursor-pointer" />
                        </div>
                        <div className="flex flex-col flex-1 p-5 gap-5">
                            {vendasLoading ? (
                                <div className='w-full h-[400px] bg-black animate-pulse' />
                            ) : (
                                vendasData?.map((venda, index) => (
                                    <div key={index} className="flex flex-col gap-5 transition-all">
                                        <div className="flex flex-row justify-between items-center p-3 bg-gray-200 rounded-xl gap-5 text-black cursor-pointer hover:opacity-65" onClick={() => toggleVendasCategory(venda.nome)}>
                                            <div className="flex flex-col">
                                                <p className="font-semibold">{venda.nome}</p>
                                            </div>
                                            <div className="flex flex-row justify-center items-center bg-gray-300 h-full p-3 rounded-lg">
                                                {openVendasCategory === venda.nome ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                                            </div>
                                        </div>
                                        {openVendasCategory === venda.nome &&
                                            venda.categorias.map((item, index) => (
                                                <div key={index} className="flex flex-col p-3 text-black border rounded-xl cursor-pointer hover:bg-gray-200" onClick={() => navegarMenu(`/categorias/${item.id}`)}>
                                                    <p>{item.nome}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
}
